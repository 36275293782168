import * as React from 'react';
import { Link as RouterLink, useParams, useLocation } from 'react-router-dom';
import ReactPlayer from 'react-player'
import { connect } from 'twilio-video';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useAuth } from '../contexts/Auth';
import Room from '../components/Room';
import AddCallProductModal from '../components/modals/AddCallProductModal';
import ErrorAlert from '../components/alerts/ErrorAlert';
import SortableTable from '../components/tables/SortableTable';
import TranscriptNotes from '../components/TranscriptNotes';

import { centsToString, dateFormatter, productFormatter, productLinkFormatter } from '../util/formatters';

const headCells = [
  {
    id: 'productName',
    label: 'Product Name',
    format: (row) => productFormatter(row)
  },
  {
    id: 'productUrl',
    label: 'Catalog Link',
    format: (row) => productLinkFormatter(row)
  },
  {
    id: 'totalSales',
    label: 'Total Sales',
    format: (row) => centsToString(row.totalSales || 0)
  }
];

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function CallDetails() {
  const auth = useAuth();
  const query = useQuery();
  const { videoCallId } = useParams();

  const [error, setError] = React.useState();
  const [roomError, setRoomError] = React.useState();
  const [isRoomLoaded, setIsRoomLoaded] = React.useState(false);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [hasProducts, setHasProducts] = React.useState(false);
  const [room, setRoom] = React.useState();
  const [callDetails, setCallDetails] = React.useState([]);
  const [customer, setCustomer] = React.useState([]);
  const [products, setProducts] = React.useState([]);
  const [isActiveCall, setIsActiveCall] = React.useState(false);
  const [justFinished, setJustFinished] = React.useState(false);
  const [hasVideo, setHasVideo] = React.useState(false);
  const [player, setPlayer] = React.useState();
  const [hasCamera, setHasCamera] = React.useState(true);

  React.useEffect(() => {
    setIsLoaded(false);
    async function fetchData() {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_LAMBDA_HOST}/calls/${videoCallId}`,
          {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${auth.token}`,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          });
        const data = await res.json();
        setCallDetails(data);
        setIsLoaded(true);
        if (data.completed) setIsActiveCall(false);
        else setIsActiveCall(query.get('isActive') === 'true');
        for (const attendee of data.attendeeUsers) {
          if (attendee.isCustomer) setCustomer(attendee)
        }
        if (data.products.length > 0) setHasProducts(true);
        const p = [];
        for (const product of data.products) {
          p.push(product.product);
        }
        if (data.videoUrl) setHasVideo(true);
        setProducts(p);
      } catch (err) {
        setError(err);
        setIsLoaded(true);
      }
      if (isActiveCall) {
        try {
          const response = await fetch(`${process.env.REACT_APP_LAMBDA_HOST}/token`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${auth.token}`,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ identity: auth.user.userName, videoCallId })
          });
          const data = await response.json();
          if (response.ok) {
            const twilioRoom = await connect(data.accessToken, {
              name: videoCallId,
              audio: true,
              video: hasCamera
            });
            setRoom(twilioRoom);
            setIsRoomLoaded(true);
            await fetch(`${process.env.REACT_APP_LAMBDA_HOST}/calls/${videoCallId}`, {
              method: 'PATCH',
              headers: {
                'Authorization': `Bearer ${auth.token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ twilioId: twilioRoom.sid })
            });
          } else {
            setRoomError('Couldn\'t fetch your call. Please refresh and try again.');
          }
        } catch (err) {
          if (err.message.toLowerCase() === 'requested device not found') {
            setHasCamera(false);
            setRoomError('Can\'t find your camera. Check your system settings to make sure a camera is available.');
          }
          if (err.message.toLowerCase() === 'permission denied') {
            setRoomError('Please allow access to your microphone and camera.');
          } else {
            console.log(err)
            setRoomError('Could not join room. Please try again.');
          }
          setIsRoomLoaded(true);
        }
      }
    }
    fetchData();
    return () => {
      setIsLoaded();
      setCallDetails();
      setRoom();
      setRoomError();
      setIsRoomLoaded();
    }
  }, [auth, query, isActiveCall, videoCallId, hasCamera])

  const leaveRoom = () => {
    setIsActiveCall(false);
    setRoom();
    setJustFinished(true);
  }

  const addProduct = (newProducts) => {
    setProducts(products.concat(newProducts));
    setHasProducts(true);
  }

  return (
    <Box sx={{ width: '100%' }}>
      {error ? <ErrorAlert error={error} /> :
        isLoaded ?
          <Box sx={{ width: '100%' }}>
            <Typography variant="h4">
              {customer.userName}
            </Typography>
            <span> {dateFormatter(callDetails.startTime, false)} &#183; <Link component={RouterLink} to={`/stores/${callDetails.storeId}/customers/${customer.userId}`}>View Profile</Link></span>
            <Grid container spacing={2} sx={{ marginTop: '20px' }}>
              <Grid item xs={8}>
                <Box sx={{
                  bgcolor: 'background.paper',
                  padding: '20px',
                  borderRadius: '5px',
                }}>
                  <Stack spacing={2}>
                    {isActiveCall ?
                      (roomError ?
                        <ErrorAlert message={roomError} /> :
                        (isRoomLoaded ?
                          <Room returnToLobby={leaveRoom} room={room} videoCallId={videoCallId} /> :
                          <CircularProgress />)) :
                      <Box>
                        {justFinished ?
                          <Alert severity="info">Your recording will be available soon.</Alert> :
                          hasVideo ?
                            <Box>
                              <ReactPlayer
                                ref={p => { setPlayer(p) }}
                                width="100%"
                                height="100%"
                                url={callDetails.videoUrl}
                                controls={true} />
                            </Box> :
                            <Alert severity="info">Your video may still be processing. Please check back later.</Alert>
                        }
                      </Box>
                    }
                    {hasProducts ?
                      <Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography variant="h6" gutterBottom>Products</Typography>
                          <AddCallProductModal callDetails={callDetails} type={"link"} addProduct={addProduct} />
                        </Box>
                        <SortableTable headCells={headCells} rows={products} idColumn="productId" />
                      </Box>
                      : <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '200px' }}>
                        <AddCallProductModal callDetails={callDetails} type={"button"} addProduct={addProduct} />
                      </Box>}
                  </Stack>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <TranscriptNotes
                  notes={callDetails.context}
                  videoCallId={videoCallId}
                  player={player} />
              </Grid>
            </Grid>
          </Box> :
          <CircularProgress />
      }
    </Box>
  );
}
