import React from 'react';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import CalendarPicker from '@mui/lab/CalendarPicker';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import ErrorAlert from '../components/alerts/ErrorAlert';
import StoreSchedule from '../components/scheduler/StoreSchedule';
import ConfirmAppointment from '../components/scheduler/ConfirmAppointment';

export default function BookAppointment() {
  let { storeId } = useParams();

  const browserTimezone = window.Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [date, setDate] = React.useState(new Date());
  const [apptTime, setApptTime] = React.useState('');
  const [stage, setStage] = React.useState('landing');
  const [store, setStore] = React.useState({});
  const [employee, setEmployee] = React.useState({});
  const [timeslots, setTimeslots] = React.useState([]);
  const [call, setCall] = React.useState([]);
  const [error, setError] = React.useState(false);
  const [createError, setCreateError] = React.useState(false);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [createIsLoaded, setCreateIsLoaded] = React.useState(false);

  React.useEffect(() => {
    setIsLoaded(false);

    async function fetchData() {
      const dateParam = date.toISOString().split('T')[0]
      const res = await fetch(`${process.env.REACT_APP_SCHEDULER_HOST}/stores/${storeId}/appointments?date=${dateParam}`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.showroomToken}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        });
      const data = await res.json();
      if (res.ok) {
        setIsLoaded(true);
        setStore(data.store);
        if (data.store.employees.length > 0) {
          setEmployee(data.store.employees[0]);
        }
        setTimeslots(data.timeslots);
      } else {
        setError(data);
        setIsLoaded(true);
      }
    }
    fetchData();
    return () => {
      setIsLoaded(false);
      setStore({});
      setTimeslots([]);
      setError();
    }
  }, [storeId, date]);

  const handleTimeSelect = (e) => {
    setApptTime(e.target.value);
    setStage('confirm');
  }

  const confirmAppt = async (details) => {
    let endTime = new Date(apptTime)
    endTime = endTime.setMinutes(endTime.getMinutes() + store.defaultDuration);

    const body = {
      callName: `Meeting for ${details.email} @ ${apptTime}`,
      startTime: apptTime,
      endTime: new Date(endTime).toISOString(),
      context: details.notes,
      isCustomerCreated: true,
      attendeeIds: [employee.userId],
      productIds: []
      // TODO, add guests
    };
    const response = await fetch(`${process.env.REACT_APP_LAMBDA_HOST}/stores/${storeId}/calls`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.showroomToken}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
    const res = await response.json();
    if (response.ok) {
      setCall(res);
      setCreateIsLoaded(true);
      setStage('scheduled');
    } else {
      setCreateError(res);
    }
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="70vh"
    >
      <Box>
        {error ? <ErrorAlert message={'Could not fetch store schedule. Please try again.'} /> : null }
        {createError ? <ErrorAlert message={'Something went wrong when trying to create your meeting. Please try again.'} /> : null }
        {stage === 'landing' ?
          <Button id="book-appt" variant="contained" onClick={() => setStage('selectTimeslot')}>Book an Appointment</Button>
          : null}
        {stage === 'selectTimeslot' ?
          <Box className="white-box">
            <StoreSchedule store={store} employee={employee} />
            <Divider orientation="vertical" variant="middle" sx={{ height: 'inherit' }} />
            <CalendarPicker date={date} onChange={(newDate) => setDate(newDate)} />
            <Divider orientation="vertical" variant="middle" sx={{ height: 'inherit' }} />
            {isLoaded ?
              <Stack spacing={2} sx={{ width: '240px', marginLeft: '20px', overflow: 'scroll', maxHeight: '360px' }}>
                {timeslots.map(slot => {
                  return <Button variant="outlined" key={slot.startTime} onClick={handleTimeSelect} value={slot.startTime}>
                    {new Date(slot.startTime).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })}
                  </Button>;
                })}
              </Stack>
              : <Box className="wrap-icon" sx={{ height: 'inherit', width: '50px', marginLeft: '22px' }}>
                <CircularProgress />
              </Box>}
          </Box>
          : null}
        {stage === 'confirm' ?
          <Box className="white-box">
            <StoreSchedule store={store} employee={employee} />
            <Divider orientation="vertical" variant="middle" sx={{ height: 'inherit', padding: '10px' }} />
            <ConfirmAppointment confirmAppt={confirmAppt} />
          </Box>
          : null}
        {stage === 'scheduled' ? createIsLoaded ?
          <Box className="white-box" sx={{ maxWidth: '800px' }}>
            <Stack spacing={2} className="center">
              <CheckCircleIcon color="success" fontSize="large" />
              <Typography variant="h5" noWrap component="div" gutterBottom>
                <strong>This meeting is scheduled</strong>
              </Typography>
              <Typography variant="body2" className="gray-text" sx={{ width: '80%', textAlign: 'center' }}>
                We emailed you and the other attendees a calendar invitation with all the details.
              </Typography>
              <Divider variant="middle" sx={{ width: '90%' }} />
              <Grid container columns={7} sx={{ width: '88%' }}>
                <Grid item xs={2}>
                  <strong>What</strong>
                </Grid>
                <Grid item xs={5} className="gray-text">
                  {store.defaultDuration} minute meeting between {call.customerName} and {employee.userName}
                </Grid>
                <Grid item xs={7} sx={{ height: '20px' }} />
                <Grid item xs={2}>
                  <strong>When</strong>
                </Grid>
                <Grid item xs={5} className="gray-text">
                  {new Date(call.startTime).toLocaleDateString('en-US', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  })} <br />
                  {new Date(call.startTime).toLocaleTimeString('en-US', {
                    hour: 'numeric',
                    minute: '2-digit',
                  })} - {store.defaultDuration} mins ({browserTimezone.replace('_', ' ')})
                </Grid>
              </Grid>
              <Divider variant="middle" sx={{ width: '90%' }} />
              <Grid container columns={7} sx={{ width: '88%' }}>
                <Grid item xs={2}>
                  <strong>Add to Calendar</strong>
                </Grid>
                <Grid item xs={5} className="gray-text">
                  <CalendarTodayIcon />
                  <CalendarTodayIcon />
                  <CalendarTodayIcon />
                </Grid>
              </Grid>
            </Stack>
          </Box>
          :
          <Box className="wrap-icon" sx={{ height: 'inherit', marginLeft: '22px' }}>
            <CircularProgress />
          </Box>
          : null}
      </Box>
    </Box >
  );
}
