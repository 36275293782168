import React from 'react';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import CreateCallModal from '../components/modals/CreateCallModal';
import ErrorAlert from '../components/alerts/ErrorAlert';
import SortableTable from '../components/tables/SortableTable';

import { centsToString, customerFormatter } from '../util/formatters';

export default function Customers() {
  let { storeId } = useParams();

  const headCells = [
    {
      id: 'userName',
      label: 'Customer',
      format: (row) => customerFormatter(row.userName, row.userId, storeId)
    },
    {
      id: 'numberOfCalls',
      label: 'Calls',
    },
    {
      id: 'totalQuantity',
      label: 'Products',
    },
    {
      id: 'totalSales',
      label: 'Total Sales',
      format: (row) => centsToString(row.totalSales)
    }
  ];

  const [error, setError] = React.useState();
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [customers, setCustomers] = React.useState([]);

  React.useEffect(() => {
    setIsLoaded(false);
    async function fetchData() {
      const res = await fetch(
        `${process.env.REACT_APP_LAMBDA_HOST}/stores/${storeId}/customers`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.showroomToken}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        });

      const data = await res.json();
      if (res.ok) {
        setCustomers(data);
        setIsLoaded(true);
      } else {
        setError(data);
        setIsLoaded(true);
        setCustomers([]);
      }
    }
    fetchData();
    return () => {
      setIsLoaded();
      setCustomers([]);
      setError();
    }
  }, [storeId])


  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '10px' }}>
        <Typography variant="h6" gutterBottom>
          {isLoaded ? `${customers.length} ` : ''}Customer(s)
        </Typography>
        <CreateCallModal />
      </Box>
      <Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
        {error ?
          <ErrorAlert /> :
          isLoaded ?
            <SortableTable headCells={headCells} rows={customers} idColumn="userId" /> :
            <CircularProgress />
        }
      </Box>
    </Box>
  );
}
