import * as React from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import FormGroup from '@mui/material/FormGroup';
import Stack from '@mui/material/Stack';
import DateTimePicker from '@mui/lab/DateTimePicker';
import TextField from '@mui/material/TextField';

import ConfirmationSnackbar from '../snackbars/ConfirmationSnackbar';
import AttendeeSelector from '../selectors/AttendeeSelector';
import ProductSelector from '../selectors/ProductSelector';
import ErrorAlert from '../alerts/ErrorAlert';

import style from './styles';

export default function CreateCallModal({ addToUpcomingCalls }) {
  const { storeId } = useParams();
  const [open, setOpen] = React.useState(false);
  const [startTime, setStartTime] = React.useState(new Date());
  const [name, setName] = React.useState('');
  const [context, setContext] = React.useState('');
  const [products, setProducts] = React.useState([]);
  const [attendees, setAttendees] = React.useState([]);
  const [error, setError] = React.useState();
  const [view, setView] = React.useState('form');
  const [roomLink, setRoomLink] = React.useState('');
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const copyToClipboard = () => {
    navigator.clipboard.writeText(roomLink);
    setSnackbarOpen(true);
  }
  const handleCreate = async (event) => {
    event.preventDefault();
    setError();
    let endTime = new Date(startTime)
    endTime = endTime.setHours(endTime.getHours() + 1);
    const body = {
      callName: name,
      startTime: startTime.toISOString(),
      endTime: new Date(endTime).toISOString(), // hard code 1 hr
      context,
      attendeeIds: attendees.map(attendee => attendee.userId),
      productIds: products.map(product => product.productId),
    }
    const response = await fetch(`${process.env.REACT_APP_LAMBDA_HOST}/stores/${storeId}/calls`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.showroomToken}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
    if (response.ok) {
      const res = await response.json();
      addToUpcomingCalls(res);
      setRoomLink(`${window.location.origin}/rooms/${res.videoCallId}`)
      setView('success');
    } else {
      const err = await response.json();
      setError(err);
    }
  }

  return (
    <Box>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant="contained" onClick={handleOpen}>Create New Call</Button>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {view === 'form' ?
            <FormGroup>
              <Stack spacing={2}>
                <Typography variant="h6" component="div">
                  Create New Call
                </Typography>
                <br />
                <DateTimePicker
                  renderInput={(props) => <TextField {...props} />}
                  label="Choose Date & Time"
                  value={startTime}
                  onChange={(newValue) => {
                    setStartTime(newValue);
                  }}
                />
                <TextField
                  id="name"
                  label="Name"
                  value={name}
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                  variant="standard"
                />
                <AttendeeSelector addAttendees={setAttendees} />
                {/* <TextField
                  id="email"
                  label="Email"
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                  variant="standard"
                /> */}
                <TextField
                  id="context"
                  label="What does the customer want to look at?"
                  variant="standard"
                  value={context}
                  onChange={(event) => {
                    setContext(event.target.value);
                  }}
                  helperText="Add notes around what they're looking to see."
                />
                <ProductSelector addSelectedProducts={setProducts} />
              </Stack>
              {error ? <ErrorAlert error={error} /> : ''}
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant="text" onClick={handleClose}>Cancel</Button>
                <Button type="submit" variant="text" onClick={handleCreate}>Create</Button>
              </Box>
            </FormGroup>
            : null}
          {view === 'success' ?
            <Box>
              <Typography variant="h6" component="div" gutterBottom>
                Call Created!
              </Typography>
              <TextField
                id="filled"
                variant="filled"
                sx={{ width: '100%' }}
                value={roomLink}
                onClick={copyToClipboard}
                helperText={'Click to copy to clipboard.'}
              />
              <ConfirmationSnackbar open={snackbarOpen} setOpen={setSnackbarOpen} message={'Copied!'} horizontal={'left'} />
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={handleClose} variant="contained">Close</Button>
              </Box>
            </Box>
            : null}
        </Box>
      </Modal>
    </Box>
  );
}
