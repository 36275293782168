import * as React from 'react';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

import ErrorAlert from '../alerts/ErrorAlert';
import { useAuth } from '../../contexts/Auth';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function AttendeeSelector({ addAttendees }) {
  const auth = useAuth();

  const [error, setError] = React.useState();
  const [attendees, setAttendees] = React.useState([]);
  const [users, setUsers] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setAttendees(value);
    addAttendees(value);
  };

  React.useEffect(() => {
    async function fetchData() {
      const res = await fetch(`${process.env.REACT_APP_LAMBDA_HOST}/users`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.showroomToken}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
      const data = await res.json();
      if (res.ok) {
        const users = data.filter(user => user.userId !== auth.user.userId);
        setUsers(users);
      } else {
        setError(data);
      }
    }
    fetchData();
    return () => {
      setUsers();
      setError();
    }
  }, [auth])

  return (
    <div>
      {error ? <ErrorAlert message="Something went wrong loading users. Please refresh and try again." /> : null}
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="product-selector">Attendees</InputLabel>
        <Select
          labelId="product-selector"
          id="product-selector"
          multiple
          value={attendees}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-products" label="Products" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value.userId} label={value.userName} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {users.map((user) => (
            <MenuItem
              key={user.userId}
              value={user}
            >
              {user.userName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}