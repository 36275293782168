import * as React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import Modal from '@mui/material/Modal';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import ErrorAlert from '../alerts/ErrorAlert';
import ProductSelector from '../selectors/ProductSelector';

import style from './styles';

export default function AddCallProduct({ addProduct, callDetails, type }) {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState();
  const [products, setProducts] = React.useState([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleAdd = async (e) => {
    e.preventDefault();
    setError();
    const body = [];
    for (const product of products) {
      body.push(product.productId);
    }
    const response = await fetch(`${process.env.REACT_APP_LAMBDA_HOST}/calls/${callDetails.videoCallId}/products`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.showroomToken}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
    const data = response.json();
    if (response.ok) {
      addProduct(products);
      handleClose();
    } else {
      setError(data);
    }
  }

  return (
    <Box>
      {type === 'link' ? <Link onClick={handleOpen}>Add Products</Link> : null}
      {type === 'button' ? <Button variant="contained" onClick={handleOpen}>Add Products</Button> : null}
      
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <FormGroup>
            <Stack spacing={2}>
              <Typography variant="h6" component="div">
                Add Products to this Call
              </Typography>
              <ProductSelector addSelectedProducts={setProducts} storeId={callDetails.storeId} />
            </Stack>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="text" onClick={handleClose}>Cancel</Button>
              <Button type="submit" variant="text" onClick={async (e) => await handleAdd(e)}>Add</Button>
            </Box>
            {error ? <ErrorAlert message={'Could not add product. Please try again.'}/> : null}
          </FormGroup>
        </Box>
      </Modal>
    </Box>
  );
}
