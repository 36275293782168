import * as React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import ErrorAlert from './alerts/ErrorAlert';
import Notes from './editors/Notes';
// import Search from './Search';

export default function TranscriptNotes({ videoCallId, notes: notesInit, player }) {
  const [transcript, setTranscript] = React.useState([]);
  const [sideView, setSideView] = React.useState('');
  const [error, setError] = React.useState('');
  const [noTranscript, setNoTranscript] = React.useState(true);
  const [notes, setNotes] = React.useState(notesInit);

  React.useEffect(() => {
    async function fetchData() {
      const res = await fetch(
        `${process.env.REACT_APP_LAMBDA_HOST}/calls/${videoCallId}/transcript`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.showroomToken}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        });
      if (res.ok && res.status === 200) {
        const data = await res.json();
        setTranscript(data);
        setSideView('transcript');
        setNoTranscript(false);
      } else {
        setSideView('notes');
      }
    }
    fetchData();
    return () => {
      setTranscript([]);
      setError();
    }
  }, [videoCallId]);

  const changeSideView = (e) => {
    e.preventDefault();
    setSideView(e.target.id);
  }

  return (
    <Stack spacing={2}>
      <ButtonGroup disableElevation size="small" aria-label="small button group">
        <Button
          id="transcript"
          key="transcript"
          variant={sideView === 'transcript' ? 'contained' : 'outlined'}
          onClick={changeSideView}
          disabled={noTranscript}
        >
          Audio Transcript
        </Button>
        <Button
          id="notes"
          key="notes"
          variant={sideView === 'notes' ? 'contained' : 'outlined'}
          onClick={changeSideView}
        >
          Notes
        </Button>
      </ButtonGroup>
      <Box>
        {sideView === 'transcript' ?
          <Box sx={{
            bgcolor: 'background.paper',
            borderRadius: '5px',
            maxHeight: '100vh',
            overflow: 'auto'
          }}>
            <Stack>
              {/* hide until implemeneted <Search /> */}
              <br />
              {error ?
                <ErrorAlert message={'Could not fetch transcript. Pleas refresh and try again.'} /> :
                transcript.map(line => {
                  return (
                    <Box
                      onClick={() => player.seekTo(line.startTime, 'seconds')}
                      key={line.id}
                      sx={
                        [
                          {
                            '&:hover': {
                              color: '#3f51b5',
                              cursor: 'pointer',
                              background: '#f0f1fa'
                            },
                          }]
                      } >
                      <Grid container spacing={2}>
                        <Grid sx={{ textAlign: 'right' }} item xs={2}>
                          <Typography variant="caption">{line.startTime}</Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <Typography variant="body2" gutterBottom>
                            {line.content}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>)
                })}
              <br />
            </Stack>
          </Box > : null
        }
        {
          sideView === 'notes' ?
            <Notes notes={notes} videoCallId={videoCallId} setNotes={setNotes} /> :
            null
        }
      </Box >
    </Stack >
  );
}
