/**
 * This represents some generic auth provider API, like Firebase.
 */
 const authProvider = {
  isAuthenticated: false,
  async login(email, password, callback) {
    const res = await fetch(`${process.env.REACT_APP_LAMBDA_HOST}/auth`, {
      method: 'POST',
      headers: {
        Authorization: `Basic ${Buffer.from(`${email}:${password}`).toString('base64')}`
      }
    });
    const jsonRes = await res.json();
    if (res.ok) {
      authProvider.isAuthenticated = true;
      callback(jsonRes);
    } else {
      throw new Error(jsonRes);
    }
  },
  logout(callback) {
    authProvider.isAuthenticated = false;
    callback();
  },
  updateUser() {}
};

export { authProvider };
