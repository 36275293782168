import { Link as RouterLink } from 'react-router-dom';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

export function centsToString(valueInCents) {
  if (!valueInCents) return 'N/A';
  return `$${(valueInCents / 100).toFixed(2)}`;
}

// https://stackoverflow.com/a/8888498
function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

// TODO: account for time zones
export function dateFormatter(startTime, setOverline = true) {
  const jsDate = new Date(startTime);
  const date = `${jsDate.getMonth() + 1}/${jsDate.getDate()}/${jsDate.getFullYear()}`
  const time = formatAMPM(jsDate);
  return <span sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
    {date} &middot; <Typography variant={setOverline ? 'overline' : ''}>{time}</Typography>
  </span>;
}

export function customerFormatter(name, userId, storeId) {
  return (<Link
    component={RouterLink}
    to={`/stores/${storeId}/customers/${userId}`}
  >
    {name}
  </Link>)
}

export function callButtonFormatter(call, storeId) {
  if (call.completed) {
    return <Button
      component={RouterLink}
      to={`/calls/${call.videoCallId}`}
      variant="outlined">Review</Button>
  } else {
    return <Button
      component={RouterLink}
      to={`/calls/${call.videoCallId}?isActive=true`}
      variant="contained">Join</Button>
  }
}

export function productFormatter(product) {
  return <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
    {product.imageUrl ? <Avatar sx={{ width: '60px', height: '60px' }} variant="rounded" src={product.imageUrl} /> : null}{product.productName}
  </Box>
}

export function productLinkFormatter(product) {
  const { productUrl } = product;
  if (productUrl) return (<Link href={product.productUrl}>Product Link</Link>);
}
