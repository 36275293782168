
import * as React from 'react';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import { useAuth } from '../../contexts/Auth';

export default function ConfirmAppointment({ confirmAppt }) {
  const auth = useAuth();

  const [name, setName] = React.useState(auth.user.userName);
  const [email, setEmail] = React.useState(auth.user.email);
  const [notes, setNotes] = React.useState('');

  const handleConfirm = () => {
    confirmAppt({ userId: auth.user.userId, name, email, notes });
  }

  return (
    <Stack spacing={2} sx={{ width: '420px', margin: '20px 40px', overflow: 'scroll', maxHeight: '360px' }}>
      <TextField
        id="name"
        label="Name"
        value={name}
        onChange={(event) => {
          setName(event.target.value);
        }}
        variant="standard"
      />
      <TextField
        id="email"
        label="Email"
        value={email}
        onChange={(event) => {
          setEmail(event.target.value);
        }}
        variant="standard"
      />
      <TextField
        id="notes"
        label="Additional Notes"
        value={notes}
        onChange={(event) => {
          setNotes(event.target.value);
        }}
        variant="standard"
        helperText="Please share anything that will help prepare for our meeting."
      />
      <div>
        <Button variant="contained" onClick={handleConfirm}>Confirm</Button>
        <Button>Cancel</Button>
      </div>
    </Stack>
  );
}