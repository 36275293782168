import * as React from 'react';

import Alert from '@mui/material/Alert';

export default function ErrorAlert(props) {
  const setMessage = () => {
    const error = props.error;
    if (error && error.name === 'ValidationError') {
      return 'Something is wrong with your input.';
    }
    return props.message || 'Something went wrong!';
  }

  return (
    <Alert sx={{ marginBottom: '10px' }} severity="error">{setMessage()}</Alert>
  );
}