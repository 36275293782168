import * as React from 'react';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

import AddProductModal from '../modals/AddProductModal';
import ErrorAlert from '../alerts/ErrorAlert';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function ProductSelector({ storeId: storeIdProp, addSelectedProducts }) {
  let { storeId } = useParams();
  const [error, setError] = React.useState();
  const [selectedProducts, setSelectedProducts] = React.useState([]);
  const [products, setProducts] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedProducts(value);
    addSelectedProducts(value);
  };

  const addProduct = (product) => {
    const allProducts = products.concat(product);
    setProducts(allProducts);
  };


  React.useEffect(() => {
    async function fetchData() {
      const res = await fetch(
        `${process.env.REACT_APP_LAMBDA_HOST}/stores/${storeId || storeIdProp}/products`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.showroomToken}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }
      )

      const data = await res.json();
      if (res.ok) {
        setProducts(data);
      } else {
        setError(data);
      }
    }
    fetchData();
    return () => {
      setProducts();
      setError();
    }
  }, [storeId, storeIdProp])

  return (
    <div>
      {error ? <ErrorAlert message="Something went wrong loading products. Please refresh and try again." /> : null}
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="product-selector">Products</InputLabel>
        <Select
          labelId="product-selector"
          id="product-selector"
          multiple
          value={selectedProducts}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-products" label="Products" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value.productId} label={value.productName} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {products.map((product) => (
            <MenuItem
              key={product.productId}
              value={product}
            >
              {product.productName}
            </MenuItem>
          ))}
        </Select>
        <AddProductModal addProduct={addProduct} />
      </FormControl>
    </div>
  );
}