import React from 'react';

import Avatar from '@mui/material/Avatar';
import { useAuth } from '../contexts/Auth';

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function setProps(user = { userName: '' }) {
  const name = user.userName;
  const nameArray = name.split(' ');
  const props = {
    sx: {
      bgcolor: stringToColor(name),
      width: '56px',
      height: '56px',
    }
  }
  if (user.avatarUrl) props.src = user.avatarUrl;
  else if (nameArray.length > 1) {
    props.children = `${nameArray[0][0]}${nameArray[1][0]}`;
  } else {
    props.children = nameArray[0][0];
  }
  return props;
}

export default function UserAvatar() {
  const auth = useAuth();

  return (
    <Avatar {...setProps(auth.user)} />
  );
}
