import React from 'react';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import AddProductModal from '../components/modals/AddProductModal';
import ErrorAlert from '../components/alerts/ErrorAlert';
import SortableTable from '../components/tables/SortableTable';

import { centsToString, productFormatter, productLinkFormatter } from '../util/formatters';

const headCells = [
  {
    id: 'productName',
    label: 'Product Name',
    format: (row) => productFormatter(row)
  },
  {
    id: 'productUrl',
    label: 'Catalog Link',
    format: (row) => productLinkFormatter(row)
  },
  {
    id: 'mentions',
    label: 'Mentions in Calls',
  },
  {
    id: 'totalSales',
    label: 'Total Sales',
    format: (row) => centsToString(row.totalSales)
  }
];

export default function Products() {
  let { storeId } = useParams();

  const [error, setError] = React.useState();
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [products, setProducts] = React.useState([]);

  React.useEffect(() => {
    setIsLoaded(false);
    async function fetchData() {
      const res = await fetch(
        `${process.env.REACT_APP_LAMBDA_HOST}/stores/${storeId}/products`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.showroomToken}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }
      );
      const data = await res.json();
      if (res.ok) {
        setProducts(data);
        setIsLoaded(true);
      } else {
        setError(data);
        setIsLoaded(true);
        setProducts([]);
      }
    }
    fetchData();
    return () => {
      setIsLoaded();
      setProducts([]);
      setError();
    }
  }, [storeId])

  const addProduct = (newProduct) => {
    newProduct.mentions = 0;
    setProducts(products.concat([newProduct]));
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '10px' }}>
        <Typography variant="h6" gutterBottom>
          {isLoaded ? `${products.length} ` : ''}Products(s)
        </Typography>
        <AddProductModal addProduct={addProduct} />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
        {error ?
          <ErrorAlert /> :
          isLoaded ?
            <SortableTable headCells={headCells} rows={products} idColumn="productId" /> :
            <CircularProgress />
        }
      </Box>
    </Box>
  );
}
