import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate
} from 'react-router-dom';
import DateAdapter from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@mui/styles';

import { AuthProvider, useAuth } from './contexts/Auth';

import Account from './views/Account';
import Calls from './views/Calls';
import CallDetails from './views/CallDetails';
import CustomerDetails from './views/CustomerDetails';
import Customers from './views/Customers';
import CustomerCallRoom from './components/room/CustomerCallRoom';
import Home from './views/Home';
import Login from './views/Login';
import Products from './views/Products';
import BookAppointment from './views/BookAppointment';
import NoMatch from './views/NoMatch';

import DrawerMenu from './components/DrawerMenu';
import { VideoProvider } from './components/room/components/VideoProvider';

import { useAppState } from './components/room/state';
import useConnectionOptions from './components/room/utils/useConnectionOptions/useConnectionOptions';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3F51B5',
    },
    secondary: {
      main: '#f0f1fa',
    },
  }
})

function RequireAuth({ children }) {
  let auth = useAuth();
  let location = useLocation();
  const token = localStorage.showroomToken;
  let isTokenExpired = true;
  if (token) {
    isTokenExpired = Date.now() >= (JSON.parse(atob(token.split('.')[1]))).exp * 1000;
  }
  if (!auth.user || isTokenExpired) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

export default function App() {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <VideoProvider options={connectionOptions} onError={setError}>
          <Router>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <div className="app">
                <Routes>
                  <Route
                    path="/login"
                    element={
                      <Login />
                    } />
                  <Route
                    path="/rooms/:videoCallId"
                    element={
                      <CustomerCallRoom />
                    } />
                  <Route
                    path="/calls/:videoCallId"
                    element={
                      <RequireAuth>
                        <DrawerMenu childComponent={<CallDetails />} />
                      </RequireAuth>
                    } />
                  <Route
                    path="/stores/:storeId/book"
                    element={
                      <RequireAuth>
                        <DrawerMenu childComponent={<BookAppointment />} />
                      </RequireAuth>
                    } />
                  <Route
                    path="/stores/:storeId/calls"
                    element={
                      <RequireAuth>
                        <DrawerMenu childComponent={<Calls />} />
                      </RequireAuth>
                    } />
                  <Route
                    path="/stores/:storeId/products"
                    element={
                      <RequireAuth>
                        <DrawerMenu childComponent={<Products />} />
                      </RequireAuth>
                    } />
                  <Route
                    path="/stores/:storeId/customers"
                    element={
                      <RequireAuth>
                        <DrawerMenu childComponent={<Customers />} />
                      </RequireAuth>
                    } />
                  <Route
                    path="/stores/:storeId/customers/:userId"
                    element={
                      <RequireAuth>
                        <DrawerMenu childComponent={<CustomerDetails />} />
                      </RequireAuth>
                    } />
                  <Route
                    path="/account"
                    element={
                      <RequireAuth>
                        <DrawerMenu childComponent={<Account />} />
                      </RequireAuth>
                    } />
                  <Route
                    path="/home"
                    element={
                      <RequireAuth>
                        <DrawerMenu childComponent={<Home />} />
                      </RequireAuth>
                    } />
                  <Route
                    path="/"
                    element={
                      <RequireAuth>
                        <DrawerMenu childComponent={<Home />} />
                      </RequireAuth>
                    } />
                  <Route path="*" element={<NoMatch />} />
                </Routes>
              </div>
            </LocalizationProvider>
          </Router>
        </VideoProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}
