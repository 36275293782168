import * as React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import ConfirmationSnackbar from '../snackbars/ConfirmationSnackbar';

export default function Notes({ notes: propNotes = '', videoCallId, setNotes: setParentNotes }) {
  const [notes, setNotes] = React.useState(propNotes);
  const [error, setError] = React.useState();
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  const saveNotes = async (e) => {
    e.preventDefault();
    try {
      const res = await fetch(`${process.env.REACT_APP_LAMBDA_HOST}/calls/${videoCallId}`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${localStorage.showroomToken}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ context: notes })
      });
      if (res.ok) {
        setParentNotes(notes);
        setSnackbarOpen(true);
      }
      else {
        const err = await res.json();
        setError(err);
        setSnackbarOpen(true);
      }
    } catch (err) {
      setError(err);
    }
  }

  return (
    <Box>
      <Stack spacing={2}>
        <ReactQuill theme="snow" value={notes} onChange={setNotes} />
        <Button type="submit" variant="outlined" onClick={saveNotes}>Save Notes</Button>
      </Stack>
      <ConfirmationSnackbar isSave={true} open={snackbarOpen} setOpen={setSnackbarOpen} error={error} />
    </Box>

  );
}
