import * as React from 'react';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} {...props} />;
});

export default function ConfirmationSnackbar({ isCreate = false, isSave = false, open, setOpen, error, message, horizontal = 'right' }) {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const messageText = () => {
    if (isCreate) return 'Created successfully!';
    if (isSave) return 'Saved successfully!';
    if (message) return message;
    return 'Success!';
  }

  return (
    <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal }} open={open} autoHideDuration={6000} onClose={handleClose}>
      {error ?
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          Something went wrong. Please try again.
        </Alert> :
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          {messageText()}
        </Alert>
      }
    </Snackbar>
  );
}
