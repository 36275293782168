import React from 'react';
import { Link } from 'react-router-dom';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { useAuth } from '../contexts/Auth';

export default function Home() {
  const auth = useAuth();

  const [error, setError] = React.useState();
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [stores, setStores] = React.useState([]);

  React.useEffect(() => {
    async function fetchData() {
      const res = await fetch(
        `${process.env.REACT_APP_LAMBDA_HOST}/users/${auth.user.userId}`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${auth.token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        });
      const data = await res.json();
      if (res.ok) {
        setIsLoaded(true);
        setStores(data.employers);
      } else {
        setIsLoaded(true);
        setError(data);
      }
    }
    fetchData();
    return () => {
      setIsLoaded();
      setStores([]);
      setError();
    }
  }, [auth])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
          m: 1,
        },
      }}
    >
      {error ? 'Something went wrong' :
        !isLoaded ? <CircularProgress /> :
          <ButtonGroup variant="text" aria-label="text button group">
            {stores.map(store => {
              return <Button key={store.storeId} component={Link} to={`/stores/${store.storeId}/calls`}>{store.storeName}</Button>
            })}
          </ButtonGroup>
      }
    </Box>
  );
}
