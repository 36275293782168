import * as React from 'react';
import {
  useNavigate,
  useLocation,
} from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import FormGroup from '@mui/material/FormGroup';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import ErrorAlert from '../components/alerts/ErrorAlert';
import { useAuth } from '../contexts/Auth';

export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();

  const from = location.state?.from?.pathname || '/home';

  const [email, setEmail] = React.useState('');
  const [error, setError] = React.useState();
  const [password, setPassword] = React.useState('');

  async function handleLogin(event) {
    event.preventDefault();
    try {
      await auth.login(email, password, () => {
        // Send them back to the page they tried to visit when they were
        // redirected to the login page. Use { replace: true } so we don't create
        // another entry in the history stack for the login page.  This means that
        // when they get to the protected page and click the back button, they
        // won't end up back on the login page, which is also really nice for the
        // user experience.
        navigate(from, { replace: true });
      });
    } catch (err) {
      setError('Invalid login. Please try again.');
    }
  }

  return (
    <Paper style={{
      backgroundImage: `url(https://upload.wikimedia.org/wikipedia/commons/f/fd/Door_in_a_white_room_%28Unsplash%29.jpg)`,
      boxShadow: 'inset 0px 0px 250px #FFFFFF',
      backgroundSize: '100%',
      backgroundPosition: 'center'
    }}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Card variant="outlined" sx={{ padding: '50px' }}>
          <form onSubmit={handleLogin}>
            <FormGroup>
              <Stack spacing={2}>
                {error ? <ErrorAlert message={error} /> : null}
                <Typography variant="h2">
                  Showroom
                </Typography>
                <TextField
                  id="email"
                  label="Email"
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                  variant="standard"
                  autoComplete="on"
                />
                <TextField
                  id="password"
                  label="Password"
                  value={password}
                  type="password"
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                  variant="standard"
                  autoComplete="on"
                />
                <Button type="submit" variant="contained">
                  Log in
                </Button>
                <Link href="#" align="center">Forgot Password?</Link>
              </Stack>
            </FormGroup>
          </form>
        </Card>
      </Box>
    </Paper >
  );
}
