import React, { Component } from 'react';

import Button from '@mui/material/Button';

import Participant from './Participant';

class Room extends Component {
  constructor(props) {
    super(props);

    this.state = {
      remoteParticipants: Array.from(this.props.room.participants.values())
    }

    this.leaveRoom = this.leaveRoom.bind(this);
    this.leaveAndEnd = this.leaveAndEnd.bind(this);
  }

  componentDidMount() {
    // Add event listeners for future remote participants coming or going
    this.props.room.on('participantConnected', participant => this.addParticipant(participant));
    this.props.room.on('participantDisconnected', participant => this.removeParticipant(participant));

    window.addEventListener("beforeunload", this.leaveRoom);
  }

  componentWillUnmount() {
    this.leaveRoom();
  }

  addParticipant(participant) {
    console.log(`${participant.identity} has joined the room.`);

    this.setState({
      remoteParticipants: [...this.state.remoteParticipants, participant]
    });
  }

  removeParticipant(participant) {
    console.log(`${participant.identity} has left the room`);

    this.setState({
      remoteParticipants: this.state.remoteParticipants.filter(p => p.identity !== participant.identity)
    });
  }

  leaveRoom() {
    this.props.room.disconnect();
    this.props.returnToLobby();
  }
 
  async leaveAndEnd() {
    // TODO: error handling
    await fetch(`${process.env.REACT_APP_LAMBDA_HOST}/calls/${this.props.videoCallId}`, {
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${localStorage.showroomToken}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ twilioId: this.props.room.sid, completed: true })
    });
    this.props.room.disconnect();
    this.props.returnToLobby();
  }

  render() {
    return (
      <div className="room">
        <div className="participants">
          <Participant key={this.props.room.localParticipant.identity} localParticipant="true" participant={this.props.room.localParticipant} />
          {
            this.state.remoteParticipants.map(participant =>
              <Participant key={participant.identity} participant={participant} />
            )
          }
        </div>
        {/* Show this button on customer view:
        <Button id="leaveRoom" variant="outlined" onClick={this.leaveRoom}>Leave Room</Button> */}
        <Button id="leaveAndEndRoom" variant="outlined" onClick={this.leaveAndEnd}>Leave and End Room</Button>
      </div>
    );
  }
}

export default Room;
