import React from 'react';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import CreateCallModal from '../components/modals/CreateCallModal';
import ErrorAlert from '../components/alerts/ErrorAlert';
import SortableTable from '../components/tables/SortableTable';

import { callButtonFormatter, centsToString, customerFormatter, dateFormatter } from '../util/formatters';

export default function Calls() {
  let { storeId } = useParams();

  const headCells = [
    {
      id: 'startTime',
      label: 'Date',
      format: (row) => dateFormatter(row.startTime)
    },
    {
      id: 'customerName',
      label: 'Customer',
      format: (row) => customerFormatter(row.customerName, row.customerId, storeId)
    },
    {
      id: 'totalSales',
      label: 'Total Sales',
      format: (row) => centsToString(row.totalSales)
    },
    {
      id: 'roomID',
      label: '',
      align: 'right',
      format: (row) => callButtonFormatter(row, storeId)
    }
  ];

  const [error, setError] = React.useState();
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [upcoming, setUpcoming] = React.useState([]);
  const [past, setPast] = React.useState([]);

  const addToUpcomingCalls = (newCall) => {
    setUpcoming(upcoming.concat(newCall));
  }

  React.useEffect(() => {
    setIsLoaded(false);

    async function fetchData() {
      const res = await fetch(`${process.env.REACT_APP_LAMBDA_HOST}/stores/${storeId}/calls`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.showroomToken}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        });
      const data = await res.json();
      if (res.ok) {
        setIsLoaded(true);
        setUpcoming(data.upcoming);
        setPast(data.past);
      } else {
        setError(data);
        setUpcoming([]);
        setPast([]);
        setIsLoaded(true);
      }
    }
    fetchData();
    return () => {
      setIsLoaded();
      setUpcoming([]);
      setPast([]);
      setError();
    }
  }, [storeId]);

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '10px' }}>
        <Typography variant="h6" gutterBottom>
          {isLoaded ? `${upcoming.length} ` : ''}Upcoming Call(s)
        </Typography>
        <CreateCallModal addToUpcomingCalls={addToUpcomingCalls} />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
        {error ?
          <ErrorAlert /> :
          isLoaded ?
            <SortableTable headCells={headCells} rows={upcoming} idColumn="videoCallId" /> :
            <CircularProgress />
        }
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '10px' }}>
        <Typography variant="h6" gutterBottom>
          {isLoaded ? `${past.length} ` : ''}Previous Call(s)
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
        {error ?
          <ErrorAlert /> :
          isLoaded ?
            <SortableTable headCells={headCells} rows={past} idColumn="videoCallId" /> :
            <CircularProgress />
        }
      </Box>
    </Box>
  );
}
