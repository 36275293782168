import React from 'react';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import CreateCallModal from '../components/modals/CreateCallModal';
import ErrorAlert from '../components/alerts/ErrorAlert';
import SortableTable from '../components/tables/SortableTable';

import { callButtonFormatter, centsToString, dateFormatter } from '../util/formatters';

export default function Calls() {
  let { storeId, userId } = useParams();

  const headCells = [
    {
      id: 'startTime',
      label: 'Date',
      format: (row) => dateFormatter(row.startTime)
    },
    {
      id: 'products',
      label: 'Products',
      format: (row) => row.products.length
    },
    {
      id: 'totalSales',
      label: 'Total Sales',
      format: (row) => {
        let total = 0;
        for (const sale of row.sales) {
          total += sale.totalInCents;
        }
        return centsToString(total);
      }
    },
    {
      id: 'roomID',
      label: '',
      align: 'right',
      format: (row) => callButtonFormatter(row, storeId)
    }
  ];

  const [error, setError] = React.useState();
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [customer, setCustomer] = React.useState([]);

  React.useEffect(() => {
    setIsLoaded(false);

    async function fetchData() {
      const res = await fetch(`${process.env.REACT_APP_LAMBDA_HOST}/stores/${storeId}/customers/${userId}`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.showroomToken}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        });
      const data = await res.json();
      if (res.ok) {
        setCustomer(data);
        setIsLoaded(true);
      } else {
        setError(data);
        setCustomer({ calls: [] });
        setIsLoaded(true);
      }
    }
    fetchData();
    return () => {
      setIsLoaded();
      setCustomer({ calls: [] });
      setError();
    }
  }, [storeId, userId]);

  const upcoming = [];
  const previous = [];
  if (isLoaded) {
    for (const call of customer.calls) {
      if (call.completed) {
        previous.push(call);
      } else {
        upcoming.push(call);
      }
    }
  }

  return (
    <Box>
      {error ? <ErrorAlert /> : null}
      {isLoaded ? <Box sx={{ marginBottom: '20px' }}>
        <Typography variant="h4">
          {customer.userName}
        </Typography>
        <span>{customer.numberOfCalls} Calls &#183; {customer.totalQuantity} Products &#183; <Link href={`mailto:${customer.email}`}>{customer.email}</Link></span>
      </Box> : null}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '10px' }}>

        <Typography variant="h6" gutterBottom>
          Upcoming Calls
        </Typography>
        <CreateCallModal />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
        {error ? null :
          isLoaded ?
            <SortableTable headCells={headCells} rows={upcoming} idColumn="videoCallId" /> :
            <CircularProgress />
        }
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '10px' }}>
        <Typography variant="h6" gutterBottom>
          Previous Calls
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
        {error ?
          null :
          isLoaded ?
            <SortableTable headCells={headCells} rows={previous} idColumn="videoCallId" /> :
            <CircularProgress />
        }
      </Box>
    </Box>
  );
}
