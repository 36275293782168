import React from 'react';

import Box from '@mui/material/Box';

export default function NoMatch() {
  return (
    <Box>
      404 Not Found
    </Box>
  );
}
