import React from 'react';

import { authProvider } from './provider';

export const AuthContext = React.createContext(null);

export function AuthProvider({ children }) {
  const storedUser = localStorage.showroomUser ? JSON.parse(localStorage.showroomUser) : null;
  const storedToken = localStorage.showroomToken || null;
  const [user, setUser] = React.useState(storedUser);
  const [token, setToken] = React.useState(storedToken);

  const login = (email, password, callback) => {
    return authProvider.login(email, password, (authRes) => {
      setUser(authRes.user);
      setToken(authRes.token);
      localStorage.showroomUser = JSON.stringify(authRes.user);
      localStorage.showroomToken = authRes.token;
      callback();
    });
  };

  const logout = (callback) => {
    return authProvider.logout(() => {
      setUser({});
      callback();
    });
  };

  const updateUser = (newUser) => {
    return authProvider.updateUser(() => {
      setUser(newUser);
      localStorage.user = JSON.stringify(newUser);
    });
  }

  const value = { user, token, login, logout, updateUser };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return React.useContext(AuthContext);
}
