import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import MapIcon from '@mui/icons-material/Map';

export default function StoreSchedule({ store, employee }) {
  const browserTimezone = window.Intl.DateTimeFormat().resolvedOptions().timeZone;

  return (
    <Box sx={{ marginRight: '15px'}}>
      <div className="gray-text">{employee.userName}</div>
      <Typography variant="h4" noWrap component="div" gutterBottom>
        <strong>{store.storeName}</strong>
      </Typography>
      <Typography variant="body" className="gray-text wrap-icon" gutterBottom>
        <AccessTimeFilledIcon fontSize="small" sx={{ marginRight: 2 }} />
        <span>{store.defaultDuration} minutes</span>
      </Typography>
      <Typography variant="body" className="wrap-icon gray-text">
        <MapIcon fontSize="small" sx={{ marginRight: 2 }} />
        <span>{browserTimezone.replace('_', ' ')}</span>
      </Typography>
    </Box>
  );
}