import * as React from 'react';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';

import ErrorAlert from '../alerts/ErrorAlert';

import style from './styles';

export default function AddProductModal({ addProduct }) {
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState('');
  const [price, setPrice] = React.useState('');
  const [error, setError] = React.useState();
  const [productUrl, setProductURL] = React.useState('');
  const [imageUrl, setImageURL] = React.useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { storeId } = useParams();

  const handleAdd = async (e) => {
    e.preventDefault();
    setError();
    const body = {
      productName: name,
      price,
      productUrl,
      imageUrl
    }
    const response = await fetch(`${process.env.REACT_APP_LAMBDA_HOST}/stores/${storeId}/products`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.showroomToken}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
    if (response.ok) {
      const product = await response.json();
      addProduct(product);
      handleClose();
    } else {
      const err = await response.json();
      setError(err);
    }
  }

  return (
    <Box>
      <Button variant="text" onClick={handleOpen}><AddIcon />Add a New Product</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <FormGroup>
            <Stack spacing={2}>
              <Typography variant="h6" component="div">
                Add a New Product
              </Typography>
              <TextField
                id="name"
                label="Name"
                value={name}
                required
                onChange={(event) => {
                  setName(event.target.value);
                }}
                variant="standard"
              />
              <TextField
                id="price"
                label="Price"
                value={price}
                required
                onChange={(event) => {
                  setPrice(event.target.value);
                }}
                variant="standard"
              />
              <TextField
                id="url"
                label="Product URL"
                value={productUrl}
                onChange={(event) => {
                  setProductURL(event.target.value);
                }}
                variant="standard"
              />
              <TextField
                id="url"
                label="Image URL"
                value={imageUrl}
                onChange={(event) => {
                  setImageURL(event.target.value);
                }}
                variant="standard"
              />
            </Stack>
            {error ? <ErrorAlert error={error} /> : ''}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="text" onClick={handleClose}>Cancel</Button>
              <Button type="submit" variant="text" onClick={async (e) => await handleAdd(e)}>Add</Button>
            </Box>
          </FormGroup>
        </Box>
      </Modal>
    </Box>
  );
}
