import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
// import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import Avatar from '../components/UserAvatar';
import ConfirmationSnackbar from '../components/snackbars/ConfirmationSnackbar';
// import DeleteAccountModal from '../components/modals/DeleteAccountModal';
// import ChangePasswordModal from '../components/modals/ChangePasswordModal';
import { useAuth } from '../contexts/Auth';


export default function Account() {
  const auth = useAuth();

  const [error, setError] = React.useState();
  const [name, setName] = React.useState(auth.user.userName);
  const [email, setEmail] = React.useState(auth.user.email);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);


  const patchCall = async function (body) {
    const response = await fetch(`${process.env.REACT_APP_LAMBDA_HOST}/users/${auth.user.userId}`, {
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${auth.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
    const data = await response.json();
    if (response.ok) {
      auth.updateUser(data);
      return data;
    } else {
      throw new Error(data);
    }
  };

  const saveChanges = async (e) => {
    e.preventDefault();
    try {
      const body = {
        userName: name,
        email
      };
      await patchCall(body);
    } catch (err) {
      setError(err);
    }
    setSnackbarOpen(true);
  }

  const resetChanges = async (e) => {
    e.preventDefault();
    setName(auth.user.userName);
    setEmail(auth.user.email);
  }

  // const updateAvatar = async (e) => {
  //   e.preventDefault();
  //   console.log('update avatar');
  //   setSnackbarOpen(true);
  // }

  // const removeAvatar = async (e) => {
  //   e.preventDefault();
  //   if (auth.user.avatarUrl) {
  //     const body = { avatarUrl: null };
  //     try {
  //       await patchCall(body);
  //     } catch (err) {
  //       setError(err);
  //     }
  //     setSnackbarOpen(true);
  //   }
  // }

  return (
    <Box sx={{
      bgcolor: 'background.paper',
      padding: '20px',
      borderRadius: '5px'
    }}>
      <ConfirmationSnackbar isSave={true} open={snackbarOpen} setOpen={setSnackbarOpen} error={error} />
      <FormGroup>
        <Stack spacing={3}>
          <Typography variant="h6" gutterBottom>
            Account Settings
          </Typography>
          <Box sx={{ minWidth: '500px', width: '50%' }}>
            <Stack spacing={3}>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '20px' }}>
                <Avatar />
                {/* <Link onClick={updateAvatar}>Update</Link>
                <Link onClick={removeAvatar}>Remove</Link> */}
              </Box>
              <TextField
                id="name"
                label="Name"
                value={name}
                onChange={(event) => {
                  setName(event.target.value);
                }}
                variant="standard"
              />
              <TextField
                id="email"
                label="Email"
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
                variant="standard"
              />
            </Stack>
          </Box>
          <Box>
            {/* <ChangePasswordModal setSnackbarOpen={setSnackbarOpen} /> */}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box>
              <Button sx={{ marginRight: '10px' }} variant="contained" type="submit" onClick={saveChanges}>Save Changes</Button>
              <Button variant="text" onClick={resetChanges}>Cancel</Button>
            </Box>
            {/* <DeleteAccountModal /> */}
          </Box>
        </Stack>
      </FormGroup>
    </Box>
  );
}
